/* External dependencies */
import { getItemFromLocalStorage, removeTypename } from '@shared/utils/helpers';
import { Col, Div, Icon, Row, Text } from 'atomize';
import { Link, navigate } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import MarkdownIt from 'markdown-it';
import React, { useEffect } from 'react';
import ReactMarkdownEditorLite from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch, useSelector } from 'react-redux';
// Local dependencies
import { RootState } from '@app/redux/store';
import {
  getAuthorName,
  getUserId,
  onChange,
} from '@entities/articles/lib/helper';
import {
  articleCategories,
  articleTypes,
  defaultAvatar,
} from '@entities/articles/lib/model';
import {
  createArticleRequest,
  informationPopup,
  resetError,
} from '@entities/articles/redux/create-article-redux/action';
import {
  getArticle,
  getArticleReset,
  updateArticleErrorReset,
  updateArticleOpenPopup,
  updateArticleRequest,
  updateArticleUpdateField,
} from '@entities/articles/redux/get-update-article-redux/actions';
import Avatar from '@entities/articles/ui/avatar/Avatar';
import Header from '@shared/ui/admin/header/Header';
import Button from '@shared/ui/button';
import FormDropdown from '@shared/ui/custom-dropdown/FormDropdown';
import FormFields from '@shared/ui/form/FormField';
import { errorMessageStyle } from '@shared/ui/form/FormStyles';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import Popup from '@shared/ui/popup/Popup';
import PrimaryPopup from '@shared/ui/popup/PrimaryPopup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import '../../../styles/style.scss';

const rowStyle = {
  d: 'flex',
  m: '0 -30px 1rem -30px',
};
const collSize = '7';

const mdParser = new MarkdownIt();

export default function ArticleDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    error,
    editedArticle,
    loading,
    isSuccess,
    isConfirmationPopupOpen,
    article,
    categoryError,
    fullVideoUrlError,
    dataError,
    titleError,
    synopsisError,
    isFormChanged,
  } = useSelector((state: RootState) => state?.getArticle);
  const { user } = useSelector((state: RootState) => state?.getUser);
  const { currentUser } = useSelector((state: RootState) => state?.login);
  const {
    loading: createArticleLoading,
    error: createArticleError,
    isSuccess: createArticleIsSuccess,
    isConfirmationPopupOpen: createArticleIsConfirmationPopupOpen,
  } = useSelector((state: RootState) => state?.createArticle);
  const { imageUrl, fullVideoUrl } = article?.heroMedia;
  const authorImage = imageUrl ? imageUrl : null;
  const userId = getUserId(user);
  const authorName = getAuthorName(user);
  const errorMessage = error?.message || createArticleError?.message;

  const isValidateFormError =
    categoryError ||
    dataError ||
    titleError ||
    !article?.title ||
    !article?.synopsis ||
    !imageUrl ||
    !article?.contents[0].data ||
    synopsisError;

  function updateField(name: string, value: any) {
    dispatch(updateArticleUpdateField({ [name]: value }));
  }

  function onSelectArticlyType(value) {
    dispatch(updateArticleUpdateField({ articleType: value }));
  }
  function onSelectArticlyCategory(value) {
    dispatch(updateArticleUpdateField({ category: value }));
  }

  function onSubmit() {
    dispatch(updateArticleOpenPopup(true));
  }

  function onSubmitToPublishToProd() {
    dispatch(informationPopup(true));
  }

  function onContinueOperation() {
    if (isConfirmationPopupOpen) {
      dispatch(updateArticleRequest({ ...editedArticle, id: article.id }));
    }

    if (createArticleIsConfirmationPopupOpen) {
      const cleanedArticle = removeTypename(article);
      dispatch(createArticleRequest(cleanedArticle));
    }
  }

  const handleEditorChange = ({ text }) => {
    dispatch(updateArticleUpdateField({ data: text }));
  };

  function onCancelOperation() {
    dispatch(informationPopup(false));
    dispatch(updateArticleOpenPopup(false));
  }

  function onCloseSuccessPopup() {
    navigate(`/admin/articles`);
  }

  function onCloseErrorPopup() {
    if (error) {
      dispatch(updateArticleErrorReset());
    } else if (createArticleError) {
      dispatch(resetError());
    }
  }

  useEffect(() => {
    const articleId = getItemFromLocalStorage('articleId');
    dispatch(getArticle(articleId));

    return () => {
      dispatch(getArticleReset());
    };
  }, []);

  return (
    <Div className="container" m="0 auto">
      <Div m={{ b: '20px' }}>
        <Header />
      </Div>
      {loading && (
        <Popup>
          <Div d="flex" justify="center" align="center" p="10px 50px 40px">
            <Icon name="Loading" size="40px" />
          </Div>
        </Popup>
      )}
      <Link to="/admin/articles" style={{ display: 'inline-block' }}>
        <Div
          d="flex"
          align="center"
          cursor="pointer"
          p="20px 0"
          m={{ l: '-13px' }}
        >
          <Icon name="LeftArrow" size="50px" />
          <Text textSize="subtitle" textWeight="600">
            {t('back')}
          </Text>
        </Div>
      </Link>

      <Div m={{ b: '30px' }}>
        <Avatar
          author={authorName}
          image={user?.avatar || defaultAvatar}
          type={user?.__typename}
        />
      </Div>
      <Div m={{ b: '40px' }}>
        <Text textSize="subtitle" textColor="primary">
          <Trans>updateArticle</Trans>
        </Text>
      </Div>
      <Div>
        <Row {...rowStyle} d="flex" m={{ b: '50px' }}>
          <Col size="6">
            <FormDropdown
              label={<Trans>articleType</Trans>}
              onSelectItem={onSelectArticlyType}
              withoutTranslation={true}
              items={articleTypes}
            >
              {article?.articleType}
            </FormDropdown>
            <FormDropdown
              withoutTranslation={true}
              label={<Trans>category</Trans>}
              onSelectItem={onSelectArticlyCategory}
              items={articleCategories}
            >
              {article?.category}
            </FormDropdown>

            <Row {...rowStyle}>
              <FormFields
                colSize={collSize}
                label={<Trans>title</Trans>}
                error={titleError}
                inputName="title"
                inputType="Textarea"
                onChange={(e) => onChange(e, updateField)}
                inputValue={article?.title}
                required
              />
            </Row>
            <Row {...rowStyle}>
              <FormFields
                colSize={collSize}
                label={<Trans>synopsis</Trans>}
                error={synopsisError}
                inputName="synopsis"
                inputStyle={{ minH: '100px' }}
                inputType="Textarea"
                onChange={(e) => onChange(e, updateField)}
                inputValue={article?.synopsis}
                required
              />
            </Row>
            <Row {...rowStyle}>
              <FormFields
                colSize={collSize}
                label={<Trans>fullVideoUrl</Trans>}
                error={fullVideoUrlError}
                inputName="fullVideoUrl"
                inputType="Textarea"
                onChange={(e) => onChange(e, updateField)}
                inputValue={fullVideoUrl}
                required
              />
            </Row>
            <Row {...rowStyle}>
              <FormFields
                colSize={collSize}
                label={<Trans>readingTime</Trans>}
                inputName="readTimeMinute"
                inputType="number"
                inputValue={article?.readTimeMinute}
                required
                disabled
              />
            </Row>
            <Row {...rowStyle}>
              <Col size="5">
                <Text
                  textColor="label_text_color"
                  textSize="body"
                  textTransform="uppercase"
                  textWeight="700"
                >
                  <Trans>updateBanner</Trans>
                </Text>
              </Col>
              <Col>
                <label className="create-article-image-input">
                  <Trans>updateBanner</Trans>
                  <input
                    type="file"
                    name="imageUrl"
                    onChange={(e) => onChange(e, updateField)}
                  />
                </label>
              </Col>
            </Row>
          </Col>
          <Col size="6">
            <Div pos="relative" m="0 auto" w="350px" h="350px">
              <img className="article-author-image" src={authorImage} alt="" />
            </Div>
          </Col>
        </Row>
        <Div>
          <Div m={{ b: '30px' }}>
            <Text textColor="main_color" textSize="display2">
              <Trans>article</Trans>
            </Text>
          </Div>
          <ReactMarkdownEditorLite
            value={article?.contents[0]?.data}
            onChange={handleEditorChange}
            renderHTML={(text) => mdParser.render(text)}
          />
          {dataError && (
            <Text textSize="caption" {...errorMessageStyle}>
              <Trans>{dataError.code}</Trans>
            </Text>
          )}
        </Div>
        <Div m={{ b: '20px' }}>
          <Row p={{ t: '3rem' }} maxW="800px">
            <Col size="4">
              <Button
                width="100%"
                typeColor="primary"
                disabled={isValidateFormError || loading}
                loading={loading}
                onClick={onSubmit}
              >
                <Trans>change</Trans>
              </Button>
            </Col>
            <Col size="4" d="none">
              <Button
                width="100%"
                typeColor="success"
                disabled={createArticleLoading}
                loading={createArticleLoading}
                onClick={onSubmitToPublishToProd}
                className="publishButton"
              >
                <Trans>publishToProd</Trans>
              </Button>
            </Col>
          </Row>
        </Div>
        {(isConfirmationPopupOpen || createArticleIsConfirmationPopupOpen) && (
          <PrimaryPopup
            title={isConfirmationPopupOpen ? 'updateArticle' : 'publishToProd'}
            btnText={isConfirmationPopupOpen ? 'change' : 'publish'}
            cancelBtnText="cancel"
            onClick={onContinueOperation}
            onClose={onCancelOperation}
            loading={loading || createArticleLoading}
          >
            <Div textColor="grey">
              {isConfirmationPopupOpen && <Trans>youWantToUpdateArticle</Trans>}
              {createArticleIsConfirmationPopupOpen && (
                <Trans>youWantToPublishArticle</Trans>
              )}
            </Div>
          </PrimaryPopup>
        )}
        {(isSuccess || createArticleIsSuccess) && (
          <SuccessPopup
            onSubmit={onCloseSuccessPopup}
            submitText="close"
            title="article"
          >
            <Div>
              <Trans>
                {isSuccess && 'successfullyUpdatedArticle'}
                {createArticleIsSuccess && 'successfullyPublishedArticle'}
              </Trans>
            </Div>
          </SuccessPopup>
        )}
        {(error || createArticleError) && (
          <ErrorPopup
            onSubmit={onCloseErrorPopup}
            submitText="close"
            title="errorOccurred"
          >
            {errorMessage}
          </ErrorPopup>
        )}
      </Div>
    </Div>
  );
}
